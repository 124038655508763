<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p>
        Determine which of the phase transitions shown below would correspond to each of the
        following combinations of enthalpy change,
        <stemble-latex content="$\Delta\text{H,}$" />
        and entropy change,
        <stemble-latex content="$\Delta\text{S.}$" />
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <th style="font-size: 15px">
              <stemble-latex content="$\text{Phase Change}$" />
            </th>
            <th style="font-size: 15px">
              <stemble-latex content="$\Delta\text{H = +}\\\Delta\text{S = +}$" />
            </th>
            <th style="font-size: 15px">
              <stemble-latex content="$\Delta\text{H = +}\\\Delta\text{S = -}$" />
            </th>
            <th style="font-size: 15px">
              <stemble-latex content="$\Delta\text{H = -}\\\Delta\text{S = +}$" />
            </th>
            <th style="font-size: 15px">
              <stemble-latex content="$\Delta\text{H = -}\\\Delta\text{S = -}$" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <stemble-latex content="$\text{Gas to Liquid}$" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="pospos" value="pospos" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="posneg" value="posneg" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="negpos" value="negpos" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
                <v-radio key="negneg" value="negneg" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Solid to Gas}$" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="pospos" value="pospos" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="posneg" value="posneg" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="negpos" value="negpos" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
                <v-radio key="negneg" value="negneg" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Solid to Liquid}$" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="pospos" value="pospos" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="posneg" value="posneg" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="negpos" value="negpos" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
                <v-radio key="negneg" value="negneg" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Gas to Solid}$" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="pospos" value="pospos" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="posneg" value="posneg" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="negpos" value="negpos" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input4" :disabled="!allowEditing">
                <v-radio key="negneg" value="negneg" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Liquid to Gas}$" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="pospos" value="pospos" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="posneg" value="posneg" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="negpos" value="negpos" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input5" :disabled="!allowEditing">
                <v-radio key="negneg" value="negneg" />
              </v-radio-group>
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Liquid to Solid}$" />
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="pospos" value="pospos" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="posneg" value="posneg" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="negpos" value="negpos" />
              </v-radio-group>
            </td>
            <td style="text-align: center">
              <v-radio-group v-model="inputs.input6" :disabled="!allowEditing">
                <v-radio key="negneg" value="negneg" />
              </v-radio-group>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question282',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
      },
    };
  },
};
</script>
